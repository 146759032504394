/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { jsx, css } from '@emotion/react'
import { useDispatch, useSelector } from 'react-redux'
import { sortBy, map, get, size, head, filter } from 'lodash'
import { FormLabelValue } from './form/FormLabelValue'
import BusyMask from './BusyMask'
import { useTranslation, Trans, Translation } from 'react-i18next'
import { FormikDropdownField } from './form/Dropdown'
import CurrencyValue from './CurrencyValue'
import CardBlackInfoText from './layout/CardBlackInfoText'
import { default_theme as theme } from '../emotion/theme'
import { PhoneNumber } from './PhoneNumber'
import { Separator } from './layout/Separator'
import { FormikRadioGroupField } from './form/RadioGroupField'
import { TraditionalRadioGroupField } from './form/TraditionalRadioGroupField'
import { subscriptionNumberChoiceList } from '../actions/subscription_number_choices'
import { phoneNumberPrefixList } from '../actions/phone_number_prefix'
import { numberPrefixTypes } from '../actions/dropdown_options'
import { FormikInputField } from './form/InputField'
import { showSuccess, showError } from '../actions/Error'

export const SubscriptionPhoneNumberSelector = ({
  customer_subscription,
  field_name,
  product,
  formik_props,
  admin_based_params,
  ...props
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const [country, setCountry] = useState(null)
  const [numberType, setNumberType] = useState(null)
  const [city, setCity] = useState(null)
  const [allocationMethod, setAllocationMethod] = useState(null)
  const billing_frequency = get(formik_props, ["values", "billing_frequency"], "annually")

  const phone_number_prefixes = useSelector( () => {
    if (
      phoneNumberPrefixList.isReady() &&
      !phoneNumberPrefixList.isLoading() &&
      !phoneNumberPrefixList.isListInvalidated()
    ) {
      return phoneNumberPrefixList.getFilteredObjects()
    } else {
      return null
    }
  })

  const country_options = useSelector(() =>
    phoneNumberPrefixList.getCountryOptions()
  )
  const number_type_options = phoneNumberPrefixList.getNumberTypeOptions({
    country: country,
  })

  const city_options = useSelector(() =>
    phoneNumberPrefixList.getCityOptions({
      country: country,
      number_type: numberType,
    })
  )

  const countrySelected = size(country_options) === 0 || country !== null
  const numberTypeSelected =
    size(number_type_options) === 0 || numberType !== null
  const citySelected = size(city_options) === 0 || city !== null

  const sample_subscription_number_options = useSelector(() =>
    subscriptionNumberChoiceList.getReservedNumbersAsOptions()
  )

    const number_type_options_with_supplement_cost =
        product &&
        sortBy(
            map(number_type_options, (number_type_option) => {
                const matching_supplement = head(
                    filter(
                        product.supplement_number_types,
                        (supplement_number_type) => {
                            return (
                                supplement_number_type.country == country &&
                                supplement_number_type.number_type == number_type_option.value
                            )
                        }
                    )
                )

                if (matching_supplement) {

                    const cost = billing_frequency == "monthly" ?
                                 matching_supplement.price_per_month_excluding_vat_euros :
                                 matching_supplement.price_per_year_excluding_vat_euros
                    
                    return Object.assign({}, number_type_option, {
                        label: `${number_type_option.label} (+€${Number.parseFloat(cost).toFixed(2)})`,
                        supplement_cost: cost
                    })
                } else {
                    number_type_option.supplement_cost = 0
                    return number_type_option
                }
            }),
            [(number_type_option) => number_type_option.supplement_cost]
        )

  useEffect(() => {
    setAllocationMethod(subscriptionNumberChoiceList.getAllocationMethod())
  }, [subscriptionNumberChoiceList.getAllocationMethod()])

  useEffect(() => {
    if (!size(country_options)) {
      return
    }
    if (countrySelected) {
      return
    }
    const default_country = head(
      filter(
        country_options,
        (x) => x.value == window.LOCAL_SETTINGS.DEFAULT_COUNTRY_ID
      )
    )
    if (default_country && country !== default_country) {
      formik_props.setFieldValue('country', default_country.value)
      setCountry(default_country.value)
    }
  }, [country_options])

  useEffect(() => {
    if (!size(number_type_options_with_supplement_cost)) {
      return
    }
    if (numberTypeSelected) {
      return
    }
    const default_number_type = head(
      sortBy(number_type_options_with_supplement_cost, (x) => x.supplement_cost)
    )
    if (default_number_type && numberType !== default_number_type) {
      formik_props.setFieldValue('number_type', default_number_type.value)
      setNumberType(default_number_type.value)
    }
  }, [number_type_options_with_supplement_cost])

  if (size(sample_subscription_number_options) > 0) {
    if (size(sample_subscription_number_options) === 1) {
      const phone_number = sample_subscription_number_options[0]
      if (get(formik_props, ['values', field_name]) !== phone_number.value) {
        formik_props.setFieldValue(field_name, phone_number.value)
      }
    }
  }

  if (
    phoneNumberPrefixList.isReady() &&
    !phoneNumberPrefixList.isLoading() &&
    !phoneNumberPrefixList.isListInvalidated()
  ) {
    if (size(country_options) === 1 && country_options[0].value !== country) {
      setCountry(country_options[0].value)
    }
    if (
      size(number_type_options) === 1 &&
      number_type_options[0].value !== numberType
    ) {
      setNumberType(number_type_options[0].value)
    }
    if (size(city_options) === 1 && city_options[0].value !== city) {
      setCity(city_options[0].value)
    }

    if (
      allocationMethod !== get(formik_props, ['values', 'allocation_method'])
    ) {
      // This is actually a hacky way to tell the container what our allocation method is,
      // we don't need actually it in the formik post values so this could be done differently.
      formik_props.setFieldValue('allocation_method', allocationMethod)
    }

    if (
      subscriptionNumberChoiceList.getReservationToken() !==
      get(formik_props, ['values', 'reservation_token'])
    ) {
      formik_props.setFieldValue(
        'reservation_token',
        subscriptionNumberChoiceList.getReservationToken()
      )
    }
  }

  const is_loading = useSelector(
    () => !phoneNumberPrefixList.isReady() || phoneNumberPrefixList.isLoading()
  )
  const readyToSelect =
    !is_loading &&
    countrySelected &&
    numberTypeSelected &&
    citySelected &&
    product.id &&
    !phoneNumberPrefixList.isLoading()

  const selected_phone_number_label = get(
    head(
      filter(
        sample_subscription_number_options,
        (x) => x.value === get(formik_props, ['values', field_name])
      )
    ),
    'label'
  )

  useEffect(() => {
    async function fetchStaticObjects() {
      dispatch(phoneNumberPrefixList.updateListFilter({ product: product.id }))
      dispatch(phoneNumberPrefixList.updatePaginationNumItemsPerPage(1000))
      dispatch(phoneNumberPrefixList.fetchListIfNeeded())
      dispatch(numberPrefixTypes.fetchListIfNeeded())
    }
    fetchStaticObjects()

    setCountry(null)
    setNumberType(null)
    setCity(null)

    formik_props.setFieldValue(field_name, null)
  }, [product])

  useEffect(() => {
    formik_props.setFieldValue('number_type', 'xoip')
  }, [formik_props.values.country])

  useEffect(() => {
    formik_props.setFieldValue('city', null)
  }, [formik_props.values.number_type])

  const createSelectorParams = () => {
    const phone_number_prefix_ids = map(
      phone_number_prefixes,
      (phone_number_prefix) => phone_number_prefix.id
    )
    return Object.assign(
      {},
      get(admin_based_params, 'extra_find_and_reserve_params', {}),
      {
        product_id: product.id,
        phone_number_prefix_ids: phone_number_prefix_ids,
        country: country,
        number_type: numberType,
        city: city,
      }
    )
  }

  useEffect(() => {
    async function fetchStaticObjects() {
      if (readyToSelect) {
        const params = createSelectorParams()
        if (size(params.phone_number_prefix_ids) > 0) {
          const on_done = (json) => {
            if (json.status !== 'success') {
              if (
                params.product_id === product.id &&
                params.country === country &&
                params.number_type === numberType &&
                phoneNumberPrefixList.getFilter().product == product.id &&
                params.city === city
              ) {
                showError(json.error_msg)
              }
            }
          }
          params.on_done = on_done
          dispatch(subscriptionNumberChoiceList.findAndReserve(params))
        }
      }
    }
    fetchStaticObjects()
  }, [readyToSelect, country, numberType, city])

  const onCountryChanged = (value) => {
    setCountry(value)
    if (size(number_type_options) > 0) {
      setNumberType(null)
    }
    if (size(city_options) > 0) {
      setCity(null)
    }
  }

  const onNumberTypeChanged = (value) => {
    setNumberType(value)
    if (size(city_options) > 0) {
      setCity(null)
    }
    formik_props.setFieldValue(field_name, null)
  }

  const onCityChanged = (value) => {
    setCity(value)
    formik_props.setFieldValue(field_name, null)
  }

  const renderCountrySelector = () => {
    return (
      <div>
        {size(country_options) > 1 && (
          <FormLabelValue spacing="h10">
            <Trans>Select a country</Trans>
            <FormikDropdownField
              name="country"
              formik_props={formik_props}
              options={country_options}
              on_change={onCountryChanged}
              placeholder={<Trans>Select a country</Trans>}
            />
          </FormLabelValue>
        )}
      </div>
    )
  }

  const renderNumberTypeSelector = () => {
    const field_name = 'number_type'
    const is_xoip_number = get(formik_props.values, field_name) === 'xoip'

    return (
      <div>
        {size(number_type_options) > 1 && (
          <FormLabelValue spacing="h10">
            <Trans>Select a number type</Trans>
            <div css={number_type_dropdown_style}>
              <FormikDropdownField
                name={field_name}
                formik_props={formik_props}
                options={number_type_options_with_supplement_cost}
                on_change={onNumberTypeChanged}
                placeholder={<Trans>Select a number type</Trans>}
              />
            </div>
            {is_xoip_number && (
              <div css={xoip_number_blurb_style}>
                <Separator variant="h5" />
                <Trans i18nKey="subscription_phonenumber_selector__xoip_numbers_are">
                  XOIP numbers are Dutch 084 numbers. They can receive calls
                  from networks within the Netherlands and the EU without
                  problem. However other networks often block these and other
                  numbers with varying rates. In case you expect to recieve
                  calls from outside the EU or from online fax sending services,
                  we recommend that you opt for a geographic number.
                </Trans>
              </div>
            )}
          </FormLabelValue>
        )}
      </div>
    )
  }

  const renderCitySelector = () => {
    return (
      <div>
        {size(city_options) > 1 && (
          <FormLabelValue spacing="h10">
            <Trans>Select a city</Trans>
            <div css={number_type_dropdown_style}>
              <FormikDropdownField
                name="city"
                formik_props={formik_props}
                options={city_options}
                on_change={onCityChanged}
                placeholder={<Trans>Select a city</Trans>}
              />
            </div>
          </FormLabelValue>
        )}
      </div>
    )
  }

  const renderPhoneNumber = (phone_number) => {
    return <PhoneNumber phone_number={phone_number} />
  }

  const renderSelectNumberFromAvailable = () => {
    return (
      <>
        {size(sample_subscription_number_options) === 0 && (
          <CardBlackInfoText>
            <Separator variant="h10" />
            <Trans>
              No numbers are immediately available. You will be notified once a
              number has been provisioned.
            </Trans>
          </CardBlackInfoText>
        )}
        {size(sample_subscription_number_options) > 1 && (
          <>
            <Trans>Select your number</Trans>
            <Separator variant="h10" />
            <TraditionalRadioGroupField
              formik_props={formik_props}
              options={sample_subscription_number_options}
              renderLabel={renderPhoneNumber}
              name={field_name}
            />
          </>
        )}
        {size(sample_subscription_number_options) == 1 && (
          <>
            <Separator variant="h10" />
            <Trans>Your new number is</Trans>
            <Separator variant="h10" />
            <div css={unselectable_number_style}>
              <PhoneNumber phone_number={selected_phone_number_label} />
            </div>
          </>
        )}
      </>
    )
  }

  const renderSelectNumberByAdmin = () => {
    if (get(admin_based_params, 'renderSelectNumberByAdmin')) {
      return admin_based_params.renderSelectNumberByAdmin()
    } else {
      return (
        <>
          <CardBlackInfoText>
            <Trans>
              It takes one or two days to activate your {numberType} number. We
              will notify you of your new number as soon as it is activated.
            </Trans>
          </CardBlackInfoText>
        </>
      )
    }
  }

  const renderSelectNumber = () => {
    return (
      <>
        {is_loading && <BusyMask />}
        {renderCountrySelector()}
        {countrySelected && renderNumberTypeSelector()}
        {countrySelected && numberTypeSelected && renderCitySelector()}
        {readyToSelect &&
          allocationMethod === 'from_available' &&
          renderSelectNumberFromAvailable()}
        {readyToSelect &&
          allocationMethod === 'by_admin' &&
          renderSelectNumberByAdmin()}
      </>
    )
  }

  return (
    <div>
      {get(customer_subscription, 'phone_number_number') && (
        <div>
          Current subscription number:{' '}
          <PhoneNumber
            phone_number={customer_subscription.phone_number_number}
          />
          <Separator variant="h10" />
        </div>
      )}

      {renderSelectNumber(formik_props)}
    </div>
  )
}

const unselectable_number_style = css`
  font-weight: bold;
`

const xoip_number_blurb_style = css`
  font-size: 14px;
  color: ${theme.colors.dark_grey};
  line-height: 1.21;
`

const number_type_dropdown_style = css`
  max-width: 350px;
`

const city_dropdown_style = css`
  max-width: 350px;
`
