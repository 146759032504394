import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Route, Switch } from 'react-router-dom'

import AdminDashboard from './AdminDashboard'
import AdminReleaseNotes from './AdminReleaseNotes'
import { AdminGlobalSearch } from './AdminGlobalSearch'
import AdminMolliePayments from './AdminMolliePayments'
import { AdminMolliePayment } from './AdminMolliePayment'
import AdminNumbers from './AdminNumbers'
import AdminNumber from './AdminNumber'
import AdminOutgoingFaxPhoneNumberPrefixes from './AdminOutgoingFaxPhoneNumberPrefixes'
import AdminOutgoingFaxPhoneNumberPrefix from './AdminOutgoingFaxPhoneNumberPrefix'
import AdminPhoneNumberPrefixes from './AdminPhoneNumberPrefixes'
import AdminPhoneNumberPrefix from './AdminPhoneNumberPrefix'
import AdminPhoneNumberPrefixSets from './AdminPhoneNumberPrefixSets'
import AdminPhoneNumberPrefixSet from './AdminPhoneNumberPrefixSet'
import AdminCustomers from './AdminCustomers'
import AdminCustomer from './AdminCustomer'
import AdminCustomerProfile from './AdminCustomerProfile'
import AdminUsers from './AdminUsers'
import AdminUser from './AdminUser'
import AdminCustomerPhoneNumber from './AdminCustomerPhoneNumber'
import AdminCustomerTransaction from './AdminCustomerTransaction'
import AdminSubscriptions from './AdminSubscriptions'
import AdminProducts from './AdminProducts'
import AdminNumberRange from './AdminNumberRange'
import AdminNumberRanges from './AdminNumberRanges'
import AdminIncomingFaxEmails from './AdminIncomingFaxEmails'
import AdminIncomingAsteriskFaxQueue from './AdminIncomingAsteriskFaxQueue'
import AdminVoxboneIncomingFaxList from './AdminVoxboneIncomingFaxList'
import AdminHylafaxIncomingFaxList from './AdminHylafaxIncomingFaxList'
import AdminOutboundEmails from './AdminOutboundEmails'
import AdminFaxLogs from './AdminFaxLogs'
import AdminInvoiceLogs from './AdminInvoiceLogs'
import AdminEditableEmails from './AdminEditableEmails'
import AdminEditableEmail from './AdminEditableEmail'
import AdminEditableEmailPreview from './AdminEditableEmailPreview'
import AdminEditableEmailCustomParameters from './AdminEditableEmailCustomParameters'
import AdminEditableEmailCustomParameter from './AdminEditableEmailCustomParameter'
import AdminTopups from './AdminTopups'
import AdminTopup from './AdminTopup'
import { AdminCities } from './AdminCities'
import { AdminCity } from './AdminCity'
import AdminOutgoingFaxServiceProviders from './AdminOutgoingFaxServiceProviders'
import AdminOutgoingFaxServiceProvider from './AdminOutgoingFaxServiceProvider'
import AdminAsteriskServerLogs from './AdminAsteriskServerLogs'
import AdminAsteriskServerLog from './AdminAsteriskServerLog'
import AdminAsteriskRoutingLookups from './AdminAsteriskRoutingLookups'
import AdminAsteriskRoutingLookup from './AdminAsteriskRoutingLookup'
import AdminAsteriskNewRoutingLookup from './AdminAsteriskNewRoutingLookup'
import { AdminGeneratedReports } from './AdminGeneratedReports'
import AdminTransactions from './AdminTransactions'
import AdminTransaction from './AdminTransaction'
import { AdminForceError } from './AdminForceError'
import AdminCdrs from './AdminCdrs'
import AdminIncomingVoiceMessages from './AdminIncomingVoiceMessages'
import { AdminAnsweringMessagesForCustomerPhoneNumber } from './AdminAnsweringMessagesForCustomerPhoneNumber' 
import { AdminAnsweringMessageForCustomerPhoneNumber } from './AdminAnsweringMessageForCustomerPhoneNumber'
import AdminFaxCostStructures  from './AdminFaxCostStructures'
import AdminFaxCostStructure  from './AdminFaxCostStructure'
import AdminFaxCostStructureVariants  from './AdminFaxCostStructureVariants'
import AdminFaxCostStructureVariant  from './AdminFaxCostStructureVariant'
import AdminCallForwardingCostStructures  from './AdminCallForwardingCostStructures'
import AdminCallForwardingCostStructure  from './AdminCallForwardingCostStructure'
import AdminCallForwardingCostStructureVariants  from './AdminCallForwardingCostStructureVariants'
import AdminCallForwardingCostStructureVariant  from './AdminCallForwardingCostStructureVariant'
import AdminIncomingFaxTest  from './AdminIncomingFaxTest'
import AdminIncomingEmailTest from './AdminIncomingEmailTest'
import AdminIncomingLegacyForwardedEmailTest from './AdminIncomingLegacyForwardedEmailTest'
import GlobalSettings from './GlobalSettings'
import AdminSyncLog from './AdminSyncLog'
import AdminBackgroundJobs from './AdminBackgroundJobs'
import { AdminUpgradePaths } from './AdminUpgradePaths'
import AdminBulkCustomerMigrationQueue from './AdminBulkCustomerMigrationQueue'

import { AdminVoiceNumberCallPlan } from './AdminVoiceNumberCallPlan'

import { isLoggedIn, loggedInUser } from '../../actions/auth'

class AuthRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                history.push('/')
            }
        }

        if ( is_logged_in && ! is_superuser ) {
            history.push('/')
        }
        
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/')
        }
    }
    
    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/admin/dashboard" exact={ true } component={ AdminDashboard } />
                <Route path="/admin/release_notes" exact={ true } component={ AdminReleaseNotes } />
                <Route path="/admin/global_search/" exact={ true } component={ AdminGlobalSearch } />
                <Route path="/admin/numbers" exact={ true } component={ AdminNumbers } />
                <Route path="/admin/number/:phone_number_id" exact={ true } component={ AdminNumber } />
                <Route path="/admin/number" exact={ true } component={ AdminNumber } />
                <Route path="/admin/number_prefixes" exact={ true } component={ AdminPhoneNumberPrefixes } />
                <Route path="/admin/number_prefix/:phone_number_prefix_id" exact={ true } component={ AdminPhoneNumberPrefix } />
                <Route path="/admin/number_prefix" exact={ true } component={ AdminPhoneNumberPrefix } />
                <Route path="/admin/number_prefix_sets" exact={ true } component={ AdminPhoneNumberPrefixSets } />
                <Route path="/admin/number_prefix_set/:phone_number_prefix_set_id" exact={ true } component={ AdminPhoneNumberPrefixSet } />
                <Route path="/admin/number_prefix_set" exact={ true } component={ AdminPhoneNumberPrefixSet } />
                <Route path="/admin/customers" exact={ true } component={ AdminCustomers } />
                <Route path="/admin/customer/:customer_id/phone_number/:customer_phone_number_id" exact={ true } component={ AdminCustomerPhoneNumber } />
                <Route path="/admin/customer/:customer_id/create_transaction" exact={ true } component={ AdminCustomerTransaction } />
                <Route path="/admin/customer/:customer_id/:active_tab" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/customer/:customer_id/:active_tab/:item_id" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/customer/:customer_id" exact={ true } component={ AdminCustomer } />
                <Route path="/admin/voice/:subscription_id/call_plan" component={ AdminVoiceNumberCallPlan } />
                <Route path="/admin/customer_profile/" exact={ true } component={ AdminCustomerProfile } />
                <Route path="/admin/customer_profile/:customer_id" exact={ true } component={ AdminCustomerProfile } />
                <Route path="/admin/users" exact={ true } component={ AdminUsers } />
                <Route path="/admin/user/:user_id" component={ AdminUser } />
                <Route path="/admin/user" exact={ true } component={ AdminUser } />
                <Route path="/admin/incoming_fax_emails" exact={ true } component={ AdminIncomingFaxEmails } />
                <Route path="/admin/incoming_asterisk_fax_queue" exact={ true } component={ AdminIncomingAsteriskFaxQueue } />
                <Route path="/admin/incoming_voxbone_fax_queue" exact={ true } component={ AdminVoxboneIncomingFaxList } />
                <Route path="/admin/incoming_hylafax_fax_queue" exact={ true } component={ AdminHylafaxIncomingFaxList } />
                <Route path="/admin/outbound_emails" exact={ true } component={ AdminOutboundEmails } />
                <Route path="/admin/fax_logs" exact={ true } component={ AdminFaxLogs } />
                <Route path="/admin/fax_logs/:fax_item_id" exact={ true } component={ AdminFaxLogs } />
                <Route path="/admin/generated_reports" exact={ true } component={ AdminGeneratedReports } />
                <Route path="/admin/invoices" exact={ true } component={ AdminInvoiceLogs } />
                <Route path="/admin/editable_emails" exact={ true } component={ AdminEditableEmails } />
                <Route path="/admin/editable_email/:editable_email_id/preview" exact={ true } component={ AdminEditableEmailPreview } />
                <Route path="/admin/editable_email/:editable_email_id" exact={ true } component={ AdminEditableEmail } />
                <Route path="/admin/editable_email_custom_parameters" exact={ true } component={ AdminEditableEmailCustomParameters } />
                <Route path="/admin/editable_email_custom_parameter/" exact={ true } component={ AdminEditableEmailCustomParameter } />
                <Route path="/admin/editable_email_custom_parameter/:editable_email_custom_parameter_id" exact={ true } component={ AdminEditableEmailCustomParameter } />
                <Route path="/admin/topups" exact={ true } component={ AdminTopups } />
                <Route path="/admin/topup/:topup_id" exact={ true } component={ AdminTopup } />
                <Route path="/admin/topup" exact={ true } component={ AdminTopup } />
                <Route path="/admin/cities" exact={ true } component={ AdminCities } />
                <Route path="/admin/city/:city_id" exact={ true } component={ AdminCity } />
                <Route path="/admin/city" exact={ true } component={ AdminCity } />
                <Route path="/admin/outgoing_fax_service_providers" exact={ true } component={ AdminOutgoingFaxServiceProviders } />
                <Route path="/admin/outgoing_fax_service_provider/:outgoing_fax_service_provider_id" exact={ true } component={ AdminOutgoingFaxServiceProvider } />
                <Route path="/admin/outgoing_fax_service_provider" exact={ true } component={ AdminOutgoingFaxServiceProvider } />
                <Route path="/admin/outgoing_fax_number_prefixes" exact={ true } component={ AdminOutgoingFaxPhoneNumberPrefixes } />
                <Route path="/admin/outgoing_fax_number_prefix" exact={ true } component={ AdminOutgoingFaxPhoneNumberPrefix } />
                <Route path="/admin/outgoing_fax_number_prefix/:outgoing_fax_number_prefix_id" exact={ true } component={ AdminOutgoingFaxPhoneNumberPrefix } />
                
                <Route path="/admin/asterisk_server_log/:asterisk_server_log_id" exact={ true } component={ AdminAsteriskServerLog } />
                <Route path="/admin/asterisk_server_logs" exact={ true } component={ AdminAsteriskServerLogs } />

                <Route path="/admin/asterisk_routing_lookups" exact={ true } component={ AdminAsteriskRoutingLookups } />
                <Route path="/admin/asterisk_routing_lookup" exact={ true } component={ AdminAsteriskNewRoutingLookup } />
                <Route path="/admin/asterisk_routing_lookup/:asterisk_routing_lookup_id" exact={ true } component={ AdminAsteriskRoutingLookup } />
                
                <Route path="/admin/transactions" exact={ true } component={ AdminTransactions } />
                <Route path="/admin/transaction/:transaction_id" exact={ true } component={ AdminTransaction } />
                <Route path="/admin/mollie_payments" exact={ true } component={ AdminMolliePayments } />
                <Route path="/admin/mollie_payment/:mollie_payment_id" exact={ true } component={ AdminMolliePayment } />
                  
                <Route path="/admin/transaction" exact={ true } component={ AdminTransaction } />
                <Route path="/admin/force_error" exact={true} component={ AdminForceError } />

                <Route path="/admin/cdrs" exact={true} component={ AdminCdrs } />
                <Route path="/admin/incoming_voice_messages" exact={true} component={ AdminIncomingVoiceMessages } />

                <Route path="/admin/customer_phone_number/:customer_phone_number_id/answering_messages" exact={true} component={ AdminAnsweringMessagesForCustomerPhoneNumber } />
                <Route path="/admin/customer_phone_number/:customer_phone_number_id/answering_message" exact={true} component={ AdminAnsweringMessageForCustomerPhoneNumber } />
                <Route path="/admin/customer_phone_number/:customer_phone_number_id/answering_message/:answering_message_id" exact={true} component={ AdminAnsweringMessageForCustomerPhoneNumber } />
                
                <Route path="/admin/fax_cost_structures" exact={true} component={ AdminFaxCostStructures } />
                <Route path="/admin/fax_cost_structure" exact={true} component={ AdminFaxCostStructure } />
                <Route path="/admin/fax_cost_structure/:fax_cost_structure_id" exact={true} component={ AdminFaxCostStructure } />

                <Route path="/admin/fax_cost_structure_variants" exact={true} component={ AdminFaxCostStructureVariants } />
                <Route path="/admin/fax_cost_structure_variant" exact={true} component={ AdminFaxCostStructureVariant } />
                <Route path="/admin/fax_cost_structure_variant/:fax_cost_structure_variant_id" exact={true} component={ AdminFaxCostStructureVariant } />

                <Route path="/admin/call_forwarding_cost_structures" exact={true} component={ AdminCallForwardingCostStructures } />
                <Route path="/admin/call_forwarding_cost_structure" exact={true} component={ AdminCallForwardingCostStructure } />
                <Route path="/admin/call_forwarding_cost_structure/:call_forwarding_cost_structure_id" exact={true} component={ AdminCallForwardingCostStructure } />

                <Route path="/admin/call_forwarding_cost_structure_variants" exact={true} component={ AdminCallForwardingCostStructureVariants } />
                <Route path="/admin/call_forwarding_cost_structure_variant" exact={true} component={ AdminCallForwardingCostStructureVariant } />
                <Route path="/admin/call_forwarding_cost_structure_variant/:call_forwarding_cost_structure_variant_id" exact={true} component={ AdminCallForwardingCostStructureVariant } />
                
                <Route path="/admin/number_ranges" exact={true} component={ AdminNumberRanges } />
                <Route path="/admin/number_range" exact={true} component={ AdminNumberRange } />
                <Route path="/admin/number_range/:number_range_id" exact={true} component={ AdminNumberRange } />
                <Route path="/admin/products" exact={true} component={ AdminProducts } />
                <Route path="/admin/subscriptions" exact={true} component={ AdminSubscriptions } />
                <Route path="/admin/product/:product_id" exact={true} component={ AdminProducts } />
                <Route path="/admin/incoming_fax_test/" exact={true} component={ AdminIncomingFaxTest } />
                <Route path="/admin/incoming_email_test/" exact={true} component={ AdminIncomingEmailTest } />
                <Route path="/admin/incoming_legacy_forwarded_email_test/" exact={true} component={ AdminIncomingLegacyForwardedEmailTest } />
                <Route path="/admin/global_settings/" exact={true} component={ GlobalSettings } />
                <Route path="/admin/sync_log/" exact={true} component={ AdminSyncLog } />
                <Route path="/admin/background_jobs/" exact={true} component={ AdminBackgroundJobs } />
                <Route path="/admin/upgrade_paths/" exact={true} component={AdminUpgradePaths} />
                <Route path="/admin/bulk_customer_migration_queue/" exact={true} component={AdminBulkCustomerMigrationQueue} />
                <Route path="/admin" component={ AdminDashboard } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
    }
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
